<template>
  <div id="kt_account_deactivate" class="collapse show">
    <form
      id="kt_account_deactivate_form"
      class="form"
      @submit.prevent="deactivateAccount()"
    >
      <div class="card-body border-top p-9">
        <div
          class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6"
        >
            <span class="svg-icon svg-icon-2tx svg-icon-warning me-4 p-4 warningBg rounded-circle">
              <inline-svg src="/media/buying/icons/warning.svg" />
            </span>
          <div class="d-flex flex-stack flex-grow-1">
            <div class="fw-bold">
              <h4 class="text-gray-800 fw-bolder">
                {{ $t("YOU ARE CLOSING YOUR COMPANY ACCOUNT") }}
              </h4>

                <div class="fs-6 text-gray-600">
                  {{ $t('To avoid unintentional delete, this requires you to confirm your Email & Password before deletion.') }} <br />
                </div>
              </div>
            </div>
          </div>

        <div class="form-check form-check-solid fv-row">
          <input
            name="deactivate"
            class="form-check-input"
            type="checkbox"
            value=""
            v-model="agreeForDelete"
            id="deactivate"
          />
          <label class="form-check-label fw-bolder ps-2 fs-6 text-gray-500" for="deactivate">
            {{ ("I confirm my Account Deletion") }}</label>
        </div>
      </div>

      <div v-if="agreeForDelete" class="card-footer d-flex justify-content-end py-6 px-9">
        <button @click="visibleDeleteModal = true"
                class="btn btn-danger main-btn fw-bold">
          {{ type === "user" ? $t("Delete my account") : $t("Close the Company Account") }}
        </button>
      </div>

      <div v-if="visibleDeleteModal">
        <ConfirmationModal
          :title="type === 'user' ? 'Deleting Profile' : 'Deleting Business'"
          :description="type === 'user' ? 'Confirm deleting your account' : 'Confirm deleting this company account, this action cannot be undone'"
          btnName="Delete"
          :popupVisible="visibleDeleteModal"
          @handleClose="visibleDeleteModal = false"
          @handleConfirm="handleConfirm"
        />
      </div>
    </form>
  </div>

  <div class="loader-wrapper" v-if="loading">
    <CustomLoader />
  </div>
</template>
<script>
  import ConfirmationModal from "../modals/ConfirmationModal";

  import store from "../../../store";
  import NotificationService from "@/buying-teams/services/NotificationService";
  import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";
  import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
  import {Mutations} from "@/store/enums/StoreEnums";

  export default {
    name: "DeactivateAccount",

    components: {
      CustomLoader,
      ConfirmationModal

    },

    props: ["type"],

    data() {
      return{
        agreeForDelete: false,
        visibleDeleteModal: false,
        loading: false,
      }
    },
    methods: {
      deactivateAccount() {

      },
      handleConfirm() {
        this.visibleDeleteModal = false;
        this.loading = true;
        store.dispatch('deactivateAccount', store.getters.currentUser.email)
        .then(async () => {
          NotificationService.swalCustom({
            title: this.$t('Success'),
            text: this.$t('Account successfully deleted.'),
            icon: "success"
          });
          await redirectToRouteWithName("sign-in");
          store.commit(Mutations.PURGE_AUTH);
        })
        .finally(() => {
          this.loading = false;
        })
      },
    }
  };
</script>
<style lang="scss">
  .loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background: #ffffff7d;
    backdrop-filter: blur(6px);
  }
  .warningBg {
    background: #F8E8AD;
    width: 62px;
    height: 62px;
  }
  @media (max-width: 600px) {
    .warningBg {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      padding: 8px !important;
      justify-content: center;
      &.svg-icon.svg-icon-2tx svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
</style>
