<template>
    <div class="collapse show companyDetailsForm">
        <el-form
            ref="profileFormRef"
            class="w-xl-75"
            :model="profileDetails"
            :rules="profileDetailsRules"
            label-width="300px">

            <el-form-item class="fullName" :label="$t('Picture')">
                <FileUploadWithCropper
                    :imageUrl="profileDetails.icon_path === Constants.USER_TMP_LOGO ? null : profileDetails.icon_path"
                    :loading="fileUploadLoading"
                    :allowedFileTypes="['png', 'jpg', 'jpeg', 'JPG']"
                    :acceptedSize="9216"
                    :blank-image-type="BlankImageTypesEnum.AVATAR"
                    :popup-title="$t('Crop Image')"
                    :popup-description="$t('Confirm your image upload by properly adjusting your Profile Picture')"
                    @getImages="handleUpload"
                    @getErrors="errors => {fileUploadErrors = errors}"
                />
                <div class="el-form-item__error">{{fileUploadErrors.join(', ')}}</div>
            </el-form-item>

            <el-form-item prop="first_name" class="fullName" :label="$t('Full Name')">
                <el-input
                    v-model="profileDetails.first_name"
                    :placeholder="$t('Input your First Name')" />
                <el-input
                    v-model="profileDetails.last_name"
                    :placeholder="$t('Input your Lat Name')" />
            </el-form-item>
            <el-form-item :label="$t('Job Title')" prop="job_title">
                <el-input
                    :placeholder="$t('Please Input')"
                    v-model="profileDetails.job_title"
                ></el-input>
            </el-form-item>
            <el-form-item prop="product_areas" :label="$t('Product Area')">
                <el-select
                    multiple
                    v-model="profileDetails.product_areas"
                    :placeholder="$t('Select Product Area')"
                    size="large"
                    collapse-tags
                >
                    <el-option
                        v-for="item in productAreas"
                        :key="item"
                        :value="item"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="mt-15" prop="country" :label="$t('Country of Residence')">
                <el-select
                    v-model="profileDetails.country"
                    :placeholder="$t('Your Country of residence')">
                    <el-option
                        v-for="(item,index) in countries"
                        :key="index"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <!--      <el-form-item class="mt-15" prop="role" :label="$t('Preferred Language')">-->
            <!--        <el-select-->
            <!--          v-model="profileDetails.language"-->
            <!--          class="preferred-language"-->
            <!--          popper-class="select-language-dropdown">-->
            <!--          <template #prefix>-->
            <!--            <img :src="profileDetailsLanguage.icon_path" width="20" height="20" alt="">-->
            <!--            <span>{{ profileDetailsLanguage.title }}</span>-->
            <!--          </template>-->
            <!--          <el-option-->
            <!--              v-for="(item, value) in supportedLanguages"-->
            <!--              :key="value"-->
            <!--              :value="value"-->
            <!--          >-->
            <!--            <div class="select-language__item">-->
            <!--              <div class="select-language__item&#45;&#45;icon">-->
            <!--                <img :src="item.icon_path" alt="">-->
            <!--              </div>-->
            <!--              <p>{{ item.title }}</p>-->
            <!--              <span>{{ item.subTitle }}</span>-->
            <!--            </div>-->
            <!--          </el-option>-->
            <!--        </el-select>-->
            <!--      </el-form-item>-->
        </el-form>
        <hr />
        <div class="d-flex mt-10 justify-content-end align-items-center">
            <el-button @click="resetForm">{{ $t("Discard") }}</el-button>
            <button
                :data-kt-indicator="buttonIndicator"
                :disabled="submitButtonState"
                class="main-btn btn"
                @click="$emit('handle-save')">
                <span class="indicator-label"> {{ $t("Save Changes") }}</span>

                <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span
              class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
            </button>
        </div>
    </div>
</template>
<script>

import store from "../../../store";
import FileUploadWithCropper from "@/buying-teams/shared-components/utils/FileUploadWithCropper";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import {Constants, SUPPORTED_LANGUAGES} from "@/core/config/constants";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import {BlankImageTypesEnum} from "@/store/enums/BlankImageTypesEnum";

export default {
    name: "BusinessProfileDetailsForm",

    emits: ['handle-save', 'handle-discard'],

    components: {
        FileUploadWithCropper,
    },

    props: {
        userData: Object,
        buttonIndicator: String,
    },

    data() {
        return {
            BlankImageTypesEnum,
            supportedLanguages: SUPPORTED_LANGUAGES,
            Constants,
            fileUploadErrors: [],
            fileUploadLoading: false,
            profileDetails: {
                icon_path: "",
                first_name: "",
                last_name: "",
                country: "",
                job_title: "",
                business_banks: [],
                product_areas: [],
                role: '',
                // language: '',
            },
            initialProfileDetailsJSON: null,
            profileDetailsRules: {
                first_name: [
                    { required: true, message: this.$t("Please input First Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                last_name: [
                    { required: true, message: this.$t("Please input Last Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                country: [
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                job_title: [
                    { required: true, message: this.$t("Please input Job title"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                product_areas: [
                    { required: true, message: this.$t("Please select Product Areas"), trigger: "change" }
                ],
            },
        };
    },
    computed: {
        productAreas() {
            return store.getters.businessAllowedProductAreas;
        },
        countries() {
            return store.getters.allCountries;
        },
        submitButtonState() {
            return this.buttonIndicator === 'on' || this.fileUploadLoading || JSON.stringify(this.profileDetails) === this.initialProfileDetailsJSON;
        },
        profileDetailsLanguage() {
            return {
                icon_path: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].icon_path : '/media/buying/icons/languages/globe-earth.svg',
                title: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].title : this.$t('Select'),
            }
        }
    },

    async mounted() {
        await store.dispatch("fetchStaticData", StaticDataEnum.PRODUCT_AREAS);
        this.fillForm({...this.userData});
        this.initialProfileDetailsJSON = JSON.stringify(this.profileDetails);
    },

    methods: {
        handleUpload(files) {
            if (!files.length) {
                this.profileDetails.icon_path = null
                return
            }

            this.fileUploadLoading = true
            store.dispatch('imageUpload', files).then(responses => {
                this.profileDetails.icon_path = responses.key
                this.fileUploadLoading = false
            }).catch(() => {
                this.fileUploadLoading = false
            })
        },

        fillForm(userData) {
            this.profileDetails = {
                role: userData.role,
                icon_path: userData.icon_path,
                first_name: userData.first_name,
                last_name: userData.last_name,
                country: userData.country,
                job_title: userData.job_title,
                product_areas: userData.product_areas,
                // language: userData.language,
                business_banks: userData.business_banks.map(item => {
                    return {
                        countries: item.countries,
                        bank_name: item.name
                    };
                })
            };
        },

        resetForm() {
            this.fillForm({...this.userData});
            this.$emit('handle-discard');
        }
    }
};
</script>
<style lang="scss">
.companyDetailsForm {
    .fullName {
        .el-form-item__content {
            display: flex;
            gap: 20px;
        }
    }
}
</style>
