<template>
    <div class="card mb-5 mb-xl-10 p-2">
        <div class="card-header border-1 cursor-pointer">
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">{{ $t("Profile Details") }}</h3>
            </div>
        </div>
        <BusinessProfileDetailsForm
            ref="profileDetailsFormRef"
            :userData="user"
            :buttonIndicator="buttonIndicator"
            @handle-save="onSubmit"
        />
    </div>


    <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 cursor-pointer">
            <div class="card-title m-0">
                <h3 class="fw-boldest m-0">{{ $t("Sign - in Method") }}</h3>
            </div>

            <!--      <div class="d-flex align-items-center">-->
            <!--        <label class="me-3 fw-bold text-gray-600">{{ $t("Secure your account") }}</label>-->
            <!--        <el-switch v-model="delivery" />-->
            <!--      </div>-->
        </div>
        <SignInMethod
            :signInMethod="user"
        />
    </div>

    <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 cursor-pointer">
            <div class="card-title m-0">
                <h3 class="fw-boldest m-0">{{ $t("Delete Account") }}</h3>
            </div>
        </div>
        <DeactivateAccount :type="'user'" />
    </div>
</template>
<script>
import BusinessProfileDetailsForm from "@/buying-teams/shared-components/settings/BusinessProfileDetailsForm.vue";
import DeactivateAccount from "@/buying-teams/shared-components/settings/DeactivateAccount";
import SignInMethod from "@/buying-teams/shared-components/settings/SignInMethod";
import store from "@/store";
import NotificationService from "../../../../services/NotificationService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { DataService } from "@/core/services/DataService";

export default {
    name: "Settings",

    components: {
        BusinessProfileDetailsForm,
        DeactivateAccount,
        SignInMethod
    },


    data() {
        return {
            delivery: "",
            profileDetails: {},
            buttonIndicator: "off"
        };
    },

    computed: {
        user() {
            return store.getters.currentUser;
        }
    },

    methods: {
        async onSubmit() {
            this.buttonIndicator = "on";
            this.profileDetails = this.$refs.profileDetailsFormRef.$refs.profileFormRef.model;
            await this.$refs.profileDetailsFormRef.$refs.profileFormRef.validate(async valid => {

                if (!valid) {
                    this.buttonIndicator = "off";
                    return;
                }

                await store.dispatch("updateProfileDetails", this.profileDetails)
                    .then(res => {
                        this.confirmProfileUpdate();
                        store.commit("ADD_AREA_TO_BUSINESS", {
                            areas: this.profileDetails.product_areas,
                            isAdmin: this.user.isAdmin
                        });
                        this.$refs.profileDetailsFormRef.initialProfileDetailsJSON = JSON.stringify(this.$refs.profileDetailsFormRef.profileDetails);
                        NotificationService.swalCustom({
                            title: "Success",
                            icon: "success"
                        });
                    }).catch(err => {
                        console.log(err, "----err");
                    }).finally(() => {
                        this.buttonIndicator = "off";
                    });
            });
        },

        confirmProfileUpdate() {
            this.user.selfUpdate({
                ...this.profileDetails,
                business_banks: this.user.business_banks
            });
            DataService.storeAuthUserData(this.user);
            setCurrentPageBreadcrumbs({ title: `${this.$t("hi")} ${this.user.first_name},  <span>${this.$t("welcomeBack")}</span>` });
        }
    }

};
</script>
<style lang="scss">

</style>
